import { combineReducers } from 'redux';
import {
  LoginState,
  RegisterState,
  UpdateProfileState,
  ChangePasswordState,
  LogoutState,
  RequestEmailState,
  ResetPasswordState,
  RefreshTokenState,
} from '../action-types';
import login from './login';
import register from './register';
import updateProfile from './update';
import changePassword from './change-password';
import logout from './logout';
import requestEmail from './forgot-password';
import resetPassword from './reset-password';
import refreshTokenState from './refresh-token';

export interface AuthModuleState {
  loginState: LoginState;
  registerState: RegisterState;
  updateProfileState: UpdateProfileState;
  changePasswordState: ChangePasswordState;
  logoutState: LogoutState;
  requestEmailState: RequestEmailState;
  resetPasswordState: ResetPasswordState;
  refreshTokenState: RefreshTokenState;
}

export default combineReducers<AuthModuleState>({
  loginState: login,
  registerState: register,
  updateProfileState: updateProfile,
  changePasswordState: changePassword,
  logoutState: logout,
  requestEmailState: requestEmail,
  resetPasswordState: resetPassword,
  refreshTokenState,
});
