import { BasicAppSettingType } from '@/graphql/generated/graphql';
import CardForm from '@commons/components/Card/CardForm';
import FormSkeleton from '@commons/components/Skeletons/FormSkeleton';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { Form, Switch } from 'antd';
import { FormProps, useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetAppSettings from '../hook/useGetAppSettings';
import useUpdateSettingApp from '../hook/useUpdateSettingApp';

const layout: FormProps = {
  layout: 'vertical',
  labelCol: { md: { span: 6 } },
  wrapperCol: { span: 18 },
};

const SettingsPage = () => {
  const { t } = useTranslation(['settings']);
  const [form] = useForm();
  const { data: settings, isLoading: loadingSettings } = useGetAppSettings();
  const { mutate: updateSetting, isLoading: loadingUpdate } = useUpdateSettingApp();
  const [statusAllowCard, setStatusAllowCard] = useState<boolean>(false);
  const { setPageTitle } = useSetPageTitle();

  useEffect(() => {
    t && setPageTitle(t('settings'));
    // eslint-disable-next-line
  }, [t]);

  useEffect(() => {
    if (settings && settings.getBasicAppSettings) {
      for (const e of settings.getBasicAppSettings) {
        if (e.type === BasicAppSettingType.SettingAllowSaveCreditCardInfo) {
          setStatusAllowCard(e?.data ? e.data : false);
        }
      }
    }
    // eslint-disable-next-line
  }, [settings]);

  const onChangeAllowCard = (value: any) => {
    updateSetting({
      input: {
        type: BasicAppSettingType.SettingAllowSaveCreditCardInfo,
        value: value ? value : false,
      },
    });
  };

  return (
    <>
      <CardForm>
        <FormSkeleton loading={loadingSettings}>
          <Form form={form} {...layout}>
            <Form.Item name="allowCard" label={t('allow-save-card-visit')} labelAlign="left">
              <Switch loading={loadingUpdate} checked={statusAllowCard} onChange={onChangeAllowCard} />
            </Form.Item>
          </Form>
        </FormSkeleton>
      </CardForm>
    </>
  );
};

export default SettingsPage;
