import { protectedComponent } from '@hoc/protectedComponent';
import SettingsPage from '@modules/Settings/page/index';

export const SettingsRouter = [
  {
    path: '/settings',
    exact: true,
    component: protectedComponent(SettingsPage),
  },
];
