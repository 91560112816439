import FormSkeleton from '@commons/components/Skeletons/FormSkeleton';
import { getHistory } from '@helpers/history';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import useChangePassword from '@modules/Auth/hooks/useChangePassword';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ChangePasswordForm from './components/ChangePasswordForm';

function ChangePasswordPage() {
  const [form] = Form.useForm();
  const { status, loading } = useChangePassword();
  const { t } = useTranslation(['change-password']);
  const { setPageTitle } = useSetPageTitle();

  useEffect(() => {
    t && setPageTitle(t('change_password'));
    // eslint-disable-next-line
  }, [t]);

  const handleChangePassword = (values: any) => {
    // const data: ChangePasswordInput = {
    //   newPassword: values.newPassword,
    //   oldPassword: values.oldPassword,
    // };
    // onChangePassword(data);
  };

  useEffect(() => {
    if (status) {
      getHistory().push('/');
    }
  }, [status, form]);

  return (
    <>
      <FormSkeleton loading={false}>
        <ChangePasswordForm form={form} onFinish={handleChangePassword} isLoading={loading} />
      </FormSkeleton>
    </>
  );
}

export default ChangePasswordPage;
