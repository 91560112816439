import { TopRestaurant, UpdateTopRestaurantItemInput } from '@/graphql/generated/graphql';
import useGetShops from '@modules/Shops/hooks/useGetShops';
import { ShopsFilter } from '@modules/Shops/types';
import { Button, Col, Form, Select, Space } from 'antd';
import { FormProps, useForm } from 'antd/lib/form/Form';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ItemShopFormUpload from './itemShopFormUpload';
import './styles.scss';

const { Option } = Select;

const layout: FormProps = {
  layout: 'horizontal',
  labelCol: { md: { span: 4 } },
  wrapperCol: { span: 20 },
};

interface Props {
  onCreate: (values?: UpdateTopRestaurantItemInput[]) => void;
  restaurantId?: string;
  listHotShop?: TopRestaurant[];
}

const ShopForm = (props: Props, ref?: any) => {
  const [form] = useForm();
  const { t } = useTranslation(['hot-shop']);

  useEffect(() => {
    if (props.listHotShop) {
      form.setFieldsValue({
        items: props.listHotShop.map((item) => {
          return { restaurantId: item.restaurantId, mediaId: item.media };
        }),
      });
    }
    // eslint-disable-next-line
  }, [props.listHotShop]);

  const onSubmit = () => {
    form.submit();
  };

  const onFinish = (value: any) => {
    if (props.restaurantId) {
    } else {
      props.onCreate(value.items);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      onSubmit,
    }),
    // eslint-disable-next-line
    [],
  );

  const [filterShop, setFilterShop] = useState<ShopsFilter>({ query: '', limit: 9999999, page: 1, showInfo: true });
  const { data } = useGetShops(
    { query: filterShop.query, showInWFO: filterShop.showInfo },
    filterShop.limit,
    filterShop.page,
  );

  return (
    <Form form={form} {...layout} onFinish={onFinish}>
      <p className="label-group"></p>
      <Form.List name="items">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
              <Col key={key} className="mb-3 border-bottom-item-hot-shop">
                <Space className="mb-3 pb-2">
                  <span className="label-item-hot-shop">
                    {t('hot-shop')} {index + 1}
                  </span>
                  <Button
                    style={{ width: 'auto' }}
                    type="dashed"
                    block
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    {t('remove')}
                  </Button>
                </Space>
                <Form.Item
                  name={[name, 'restaurantId']}
                  fieldKey={[fieldKey, 'restaurantId']}
                  label={t('shop')}
                  labelAlign="left"
                  rules={[{ required: true, message: t('validate.required') }]}
                >
                  <Select
                    placeholder={t('select-shop')}
                    loading={false}
                    showSearch
                    allowClear
                    onSearch={(e) => {
                      setFilterShop({
                        ...filterShop,
                        query: e,
                      });
                    }}
                    // dropdownRender={(menu) => {
                    //   const totalPage =
                    //     (data?.findAllRestaurants?.total || 0) < filterShop.limit
                    //       ? 1
                    //       : Math.ceil((data?.findAllRestaurants?.total || 0) / filterShop.limit);

                    //   return (
                    //     <React.Fragment>
                    //       {menu}
                    //       <Row className="ml-2 mr-2 mt-2 mb-2 justify-content-center">
                    //         <Col className="mr-2">
                    //           <Button
                    //             onMouseDown={() => {
                    //               if (filterShop.page <= 1) {
                    //                 return;
                    //               }
                    //               setFilterShop({
                    //                 ...filterShop,
                    //                 page: 1,
                    //               });
                    //             }}
                    //           >
                    //             Reset
                    //           </Button>
                    //         </Col>
                    //         <Col className="mr-2">
                    //           <Button
                    //             onMouseDown={() => {
                    //               if (filterShop.page <= 1) {
                    //                 return;
                    //               }
                    //               setFilterShop({
                    //                 ...filterShop,
                    //                 page: filterShop.page - 1,
                    //               });
                    //             }}
                    //           >
                    //             Pre
                    //           </Button>
                    //         </Col>
                    //         <Col className="mr-2">
                    //           <Button
                    //             onMouseDown={() => {
                    //               if (filterShop.page >= totalPage) {
                    //                 return;
                    //               }
                    //               setFilterShop({
                    //                 ...filterShop,
                    //                 page: filterShop.page + 1,
                    //               });
                    //             }}
                    //           >
                    //             Next
                    //           </Button>
                    //         </Col>
                    //         <Col className="mt-1">{`${filterShop.page} / ${totalPage}`}</Col>
                    //       </Row>
                    //     </React.Fragment>
                    //   );
                    // }}
                  >
                    {data &&
                      data.findAllRestaurants &&
                      data.findAllRestaurants.data &&
                      data.findAllRestaurants.data
                        .filter((item) => item.showInWFO)
                        .map((item, index) => {
                          return (
                            <Option key={index} value={item._id}>
                              {item.name}
                            </Option>
                          );
                        })}
                  </Select>
                </Form.Item>
                <ItemShopFormUpload name={name} fieldKey={fieldKey} />
              </Col>
            ))}
            <Form.Item>
              <Button className="btn-add-new-item-hot-shop" style={{ width: 'auto' }} onClick={() => add()} block>
                {t('add_new')}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default forwardRef(ShopForm);
