import i18n from '@/i18n';
import { DeleteOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import iconDefault from '@assets/images/icons/icon_default_input_image.svg';
import iconPlus from '@assets/images/icons/icon_plus_upload.svg';
import { NotificationError } from '@commons/components/Notification';
import { getImageUrl, getDimensions } from '@helpers/function-helper';
import { Button, message, Space, Upload } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import { uploadFileApi } from './services/apis';

import './style.scss';

function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

interface IFProps {
  value?: any;
  onChange?(media: any | undefined): void;
  width?: number;
  height?: number;
  size?: number;
  mustBeSquare?: boolean;
  disable?: boolean;
  accept?: string;
  dimensions?: any;
  classBtnUpload?: string;
  classUploadContainer?: string;
  classContainerView?: string;
  styleUploadContainer?: any;
  styleBtnUpload?: any;
  styContainerView?: any;
  styleViewImage?: any;
  errorRequired?: boolean;
}

export default function CustomUploadDragger(props: IFProps) {
  const beforeUpload = useCallback(
    async (file: any) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        NotificationError(i18n.t('notification.warning'), i18n.t('error.only-img'));
        throw new Error(' ');
      }
      const isLt2M = file.size / 1024 / 1024 < (props.size ? props.size : 10);
      if (!isLt2M) {
        NotificationError(i18n.t('notification.warning'), i18n.t('error.max-size-img'));
        throw new Error(' ');
      }
      const dimensions = await getDimensions(file);

      if (props.mustBeSquare === true && dimensions.width !== dimensions.height) {
        message.error('Ảnh phải là hình vuông');
        throw new Error('Ảnh phải là hình vuông');
      }

      if (props.height !== undefined && props.width !== undefined) {
        if (dimensions.width !== props.width || dimensions.height !== props.height) {
          NotificationError(
            i18n.t('notification.warning'),
            `  ${i18n.t('error.error-size-image')} ${props.width} x ${props.height}`,
          );

          throw new Error('Ảnh phải có kích thước: ' + props.width + 'x' + props.height);
        }
      }
      if (props.height !== undefined && props.width === undefined) {
        if (dimensions.height !== props.height) {
          NotificationError(
            i18n.t('notification.warning'),
            `  ${i18n.t('error.error-size-image')} ${props.width} x ${props.height}`,
          );
          throw new Error('Kích thước sai, phải là: ... X ' + props.height);
        }
      }
      if (props.width !== undefined && props.height === undefined) {
        if (dimensions.width !== props.width) {
          NotificationError(
            i18n.t('notification.warning'),
            `  ${i18n.t('error.error-size-image')} ${props.width} x ${props.height}`,
          );
          throw new Error('Kích thước sai, phải là: ' + props.width + 'x ...');
        }
      }
    },
    // eslint-disable-next-line
    [props.width, props.height, props.mustBeSquare],
  );

  const [state, setState] = useState({
    imageUrl: props.value?.url ? getImageUrl(props.value.url) : '',
    loading: false,
    value: props.value,
  });

  const [stateIMG, setStateIMG] = useState({
    previewVisible: false,
  });

  useEffect(() => {
    if (props.value) {
      setState({ ...state, imageUrl: props.value.url });
    } else {
      setState({ ...state, imageUrl: '', value: '' });
    }
    // eslint-disable-next-line
  }, [props.value]);

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      props.onChange && props.onChange(undefined);
      setState({
        ...state,
        loading: true,
        value: undefined,
      });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl: string) =>
        setState({
          ...state,
          imageUrl,
          loading: false,
        }),
      );
    }
  };

  const customRequest = ({ onSuccess, onError, file }: any) => {
    uploadFileApi(file)
      .then((r) => {
        props.onChange && props.onChange(r.cmsUploadFile || undefined);
        setState({
          ...state,
          value: r.cmsUploadFile || undefined,
        });
        onSuccess();
      })
      .catch((error) => {
        props.onChange && props.onChange(undefined);
        onError(error);
      });
  };

  return (
    <>
      {state.imageUrl ? (
        <div
          className={`${props.classContainerView || ''} container_view_upload`}
          style={{ ...props.styContainerView }}
        >
          <Modal
            className="modal-view-image-upload"
            visible={stateIMG.previewVisible}
            onCancel={() => {
              setStateIMG({ previewVisible: false });
            }}
            footer={null}
          >
            <img alt="ImgProduct" src={state.imageUrl} className="img-product" />
          </Modal>
          <div className="view_img position-relative" style={{ ...props.styleViewImage, paddingBottom: 0 }}>
            <input
              type="image"
              src={state.imageUrl}
              value={props.value?._id}
              className="image-media-upload"
              alt="avatar"
            />
            <div
              className="bg-image"
              style={{
                backgroundImage: `url(${state.imageUrl})`,
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'absolute',
              }}
            />
          </div>

          <div className="middle_custom">
            {props.disable ? undefined : (
              <Space>
                <Button
                  type="ghost"
                  shape="circle"
                  icon={<DeleteOutlined style={{ fontSize: 22 }} className="color-icons" />}
                  size="middle"
                  title="Xóa"
                  onClick={() => {
                    props.onChange && props.onChange(undefined);
                    setState({
                      imageUrl: '',
                      loading: false,
                      value: { id: '', createdBy: '', fileName: '', fileType: '', size: 0, uri: '' },
                    });
                  }}
                />
                <Button
                  type="ghost"
                  shape="circle"
                  icon={<EyeOutlined style={{ fontSize: 22 }} width={45} className="color-icons" />}
                  size="middle"
                  title="Xem"
                  onClick={() => {
                    setStateIMG({ previewVisible: true });
                  }}
                />
              </Space>
            )}
          </div>
        </div>
      ) : (
        <Upload.Dragger
          name="files"
          accept={props?.accept}
          className={`upload_custom ${props.classUploadContainer ? props.classUploadContainer : ''}  ${
            props.errorRequired ? 'upload_custom_err_bg' : ''
          } `}
          style={{ ...props.styleUploadContainer }}
          customRequest={customRequest}
          multiple={false}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          disabled={props.disable}
        >
          <div
            className={`button_upload ${props.classBtnUpload ? props.classBtnUpload : ''}`}
            style={{ ...props.styleBtnUpload }}
          >
            {state.loading ? <LoadingOutlined /> : <img src={iconDefault} className="icon_img_btn" alt="" />}
            <div className="d-flex align-item-center justify-content-center mt-4 ">
              <img src={iconPlus} className="mr-2" alt="" />
              <span className="label_btn_upload"> {i18n.t('Upload images')}</span>
            </div>
          </div>
        </Upload.Dragger>
      )}
    </>
  );
}
