import ButtonDeleteRed from '@commons/components/ButtonRowActions/ButtonDeleteRed';
import ModalConfirm from '@commons/components/modals/ModalConfirm';
import TableHeader from '@commons/components/TableHeader';
import TableWithNumber from '@commons/components/TableWithNumber';
import { getImageUrl } from '@helpers/function-helper';
import { getHistory } from '@helpers/history';
import { getNewHotShopUrl } from '@helpers/url';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { Button, Form, Image, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import TextTruncate from 'react-text-truncate';
import useGetAllTopRestaurant from '../hook/useGetAllTopRestaurant';
import useUpdateTopRestaurant from '../hook/useUpdateTopRestaurant';

const HotShop = () => {
  const { t } = useTranslation(['hot-shop']);
  const { isLoading, data } = useGetAllTopRestaurant();
  const { mutate: updateTopRestaurant, isLoading: loadingDelete, isSuccess } = useUpdateTopRestaurant();
  const [deleteState, setDeleteState] = useState<any | undefined>();
  const onDeleteTopRestaurant = () => {
    if (data?.getAllTopRestaurants && deleteState) {
      const newData = data?.getAllTopRestaurants
        .filter((item: any, index: number) => {
          return index !== deleteState.index;
        })
        .map((item) => {
          return { restaurantId: item.restaurantId, mediaId: item.mediaId };
        });
      updateTopRestaurant({ input: { restaurants: newData } });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      onCancelDelete();
    }
  }, [isSuccess]);

  const onCancelDelete = () => {
    setDeleteState(undefined);
  };

  const { setPageTitle } = useSetPageTitle();
  useEffect(() => {
    t && setPageTitle(t('hot-shop'));
    // eslint-disable-next-line
  }, [t]);

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      key: '#',
      dataIndex: '#',
      width: 80,
      align: 'center',
    },
    {
      title: t('images'),
      dataIndex: 'mediaId',
      key: 'mediaId',
      width: 200,
      render: (icon: any, record) => {
        if (record && record?.media.url.length > 0) {
          return <Image className="table-item-img" src={record?.media.url} />;
        }
        return <Image className="table-item-img" src={getImageUrl('')} />;
      },
    },
    {
      title: t('shop'),
      key: 'restaurant',
      dataIndex: 'restaurant',

      render: (value: any, record) => (
        <TextTruncate
          containerClassName="w-100 d-block"
          line={2}
          element="span"
          truncateText="…"
          text={record.restaurant?.name}
        />
      ),
    },
    {
      title: t('action'),
      width: 120,
      render: (_, record: any, index: number) => {
        return (
          <Space>
            <ButtonDeleteRed
              onClick={() => {
                setDeleteState({ restaurant: record, index: index });
              }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <TableHeader>
        <div className="table-header-flex d-block d-xl-flex justify-content-between align-items-center mb-4">
          <div />
          <div className="table-actions">
            <Form layout="inline" className="justify-content-start justify-content-xl-end">
              <Button
                size="middle"
                onClick={() => {
                  getHistory().push(getNewHotShopUrl());
                }}
                className="btn-dangerous btn-add-new mb-2"
              >
                {t('edit')}
              </Button>
            </Form>
          </div>
        </div>
      </TableHeader>

      <TableWithNumber
        scroll={{
          x: 1150,
        }}
        rowKey={(data: any, index) => `${data.restaurantId}_${index}`}
        columns={columns}
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'table-row-height table-row-white ' : 'table-row-height table-row-gray'
        }
        dataSource={data?.getAllTopRestaurants}
        loading={isLoading}
        pagination={false}
      />

      <ModalConfirm
        isLoading={loadingDelete}
        data={deleteState}
        handleOk={onDeleteTopRestaurant}
        handleCancel={onCancelDelete}
        title={t('delete_hot_shop')}
        okTitle={t('delete')}
        content={
          <Trans
            i18nKey="delete_content"
            t={t}
            values={{ name: deleteState?.restaurant?.restaurant?.name || '' }}
            components={[<span className="primary_color fw-500"></span>]}
          />
        }
      />
    </>
  );
};

export default HotShop;
