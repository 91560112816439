import React from 'react';
import SupperAdminLeftNavigation from './SupperAdmin';

// tslint:disable-next-line:interface-name
interface IProps {
  isCollapsed: boolean;
}

export default function LeftNavigation({ isCollapsed }: IProps) {
  // const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  return (
    <>
      {/* Phân quyền truy cập cho các user */}
      {/* Role Super_Admin */}
      <SupperAdminLeftNavigation isCollapsed={isCollapsed} />
      {/* {loginState.data?.role === Roles.SuperAdmin ? (
        <SupperAdminLeftNavigation isCollapsed={isCollapsed} />
      ) : // Role Admin
      loginState.data?.role === Roles.Admin ? (
        <AdminLeftNavigation isCollapsed={isCollapsed} />
      ) : // Role Editor
      loginState.data?.role === Roles.Editor ? (
        <EditorLeftNavigation isCollapsed={isCollapsed} />
      ) : null} */}
    </>
  );
}
