import { Jwt } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import {
  REFRESH_TOKEN,
  REFRESH_TOKEN_ERROR,
  REFRESH_TOKEN_SUCCESS,
  RefreshTokenActionTypes,
} from '../action-types/refresh-token';

export const refreshToken = (): RefreshTokenActionTypes => ({
  type: REFRESH_TOKEN,
});
export const refreshTokenSuccess = (payload: Jwt): RefreshTokenActionTypes => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload,
});
export const refreshTokenError = (payload: AppError): RefreshTokenActionTypes => ({
  type: REFRESH_TOKEN_ERROR,
  payload,
});
