import ShowSideBar from '@assets/images/show_sidebar.svg';
import logoVertical from '@assets/images/logo_login.png';
import logo from '@assets/images/logo_sidebar_h.png';
import LogoutIcon from '@assets/images/icons/sign-out.svg';
import { useWindowSize } from '@hooks/useWindowSize';
import { Layout } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import LoggedInUser from '../Header/LoggedInUser';
import LeftNavigation from '../Navigation/Left';
import TopNavigation from '../Navigation/Top';
import './style.scss';
import useLogout from '@modules/Auth/hooks/useLogout';
import { refreshToken } from '@modules/Auth/redux/actions';
import { RootState } from '@redux/reducers';
import { useDispatch, useSelector } from 'react-redux';

const { Header, Sider, Content } = Layout;

const MasterLayout = (props: any) => {
  const size = useWindowSize();
  const { onLogout } = useLogout();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collapsedWidth, setCollapsedWidth] = useState(80);
  const toggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  const isFirst = useRef(true);
  useEffect(() => {
    if (size.width <= 1024 && isFirst) {
      setIsCollapsed(true);
      isFirst.current = false;
      if (size.width <= 480) {
        setCollapsedWidth(0);
      } else {
        setCollapsedWidth(80);
      }
    } else {
      setCollapsedWidth(80);
    }
  }, [size]);

  const handleLogout = () => {
    onLogout('');
  };

  //refreshToken
  const IntervalRefreshToken = useRef<any>();
  const minutesRefresh = 10 * 60 * 1000; //10p
  const appLoaded = useSelector<RootState>((state) => state.app.loaded);
  const dispatch = useDispatch();

  useEffect(() => {
    if (appLoaded) {
      IntervalRefreshToken.current = setInterval(() => {
        dispatch(refreshToken());
      }, minutesRefresh);
    }
    return () => {
      IntervalRefreshToken.current && clearInterval(IntervalRefreshToken.current);
    };
    // eslint-disable-next-line
  }, [appLoaded]);

  return (
    <Layout className="master-layout">
      <Layout className="master-content" hasSider={true}>
        <Sider
          trigger={null}
          collapsible
          collapsed={isCollapsed}
          theme="dark"
          collapsedWidth={collapsedWidth}
          width={235}
          className={`left-menu-sidebar`}
        >
          <div className="logo">
            <img style={{ maxWidth: '100%' }} src={!isCollapsed ? logo : logoVertical} alt="logo" />
          </div>
          <LeftNavigation isCollapsed={isCollapsed} />
          <div className="navigation-logout" onClick={handleLogout}>
            <img src={LogoutIcon} alt="Logout" className="mr-3" />
            {!isCollapsed ? <span>Logout</span> : null}
          </div>
        </Sider>
        <Layout>
          {/* <Breadcrumbs /> */}

          <Header className="header d-flex justify-content-between pr-3 align-items-center bg-white">
            <div className="d-flex align-items-center header_left">
              <img className="mr-3 cursor-pointer" onClick={toggle} src={ShowSideBar} alt="ic_show" />
              <TopNavigation />
            </div>
            <LoggedInUser />
          </Header>

          <Content
            className="master-layout master-layout-content"
            style={{
              minHeight: 280,
            }}
            id="master-layout-content"
          >
            {props.children}
            {!!props.route && renderRoutes(props.route.routes)}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default React.memo(MasterLayout);
