import { User } from '@/graphql/generated/graphql';
import { updateLocalToken } from '@helpers/token';
import { UPDATE_PROFILE_SUCCESS } from '../action-types';
import { LOGIN, LoginActionTypes, LoginState, LOGIN_BY_CODE, LOGIN_ERROR, LOGIN_SUCCESS } from '../action-types/login';

const initialState = {
  loading: false,
};

export default (state = initialState, action: LoginActionTypes): LoginState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_BY_CODE:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.payload,
          role: action.payload.role,
          companyId: action.payload.companyId,
        },
        loading: false,
      };
    case UPDATE_PROFILE_SUCCESS:
      updateLocalToken(action.payload as User);
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
