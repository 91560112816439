export const APP_STARTED = 'APP_STARTED';
export const APP_STARTED_SUCCESS = 'APP_STARTED_SUCCESS';
export const APP_STARTED_ERROR = 'APP_STARTED_ERROR';
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';

export interface AppStartAction {
  type: typeof APP_STARTED;
}

export interface AppStartActionSuccess {
  type: typeof APP_STARTED_SUCCESS;
}

export interface AppStartActionError {
  type: typeof APP_STARTED_ERROR;
}

export interface SetPageTitleAction {
  type: typeof SET_PAGE_TITLE;
  payload: string;
}

export type AppActionTypes = AppStartAction | AppStartActionSuccess | AppStartActionError | SetPageTitleAction;
