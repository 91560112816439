import { NotificationSuccess } from '@commons/components/Notification';
// import * as apis from '@modules/Auth/services/apis';
import { put } from 'redux-saga/effects';
import { RequestEmailAction } from '../action-types';
import { requestEmailError, requestEmailSuccess } from '../actions';

export function* requestEmailAsync(action: RequestEmailAction) {
  try {
    // yield delay(800);
    // yield apis.forgotPasword(action.payload);
    yield put(requestEmailSuccess(action.payload));
  } catch (error) {
    yield put(requestEmailError(error));
  }
}

export function requestEmailSuccessAsync() {
  NotificationSuccess('notification', 'confirm_email');
}
