import env from '@/env';
import { Jwt, User } from '@/graphql/generated/graphql';
import i18n from '@/i18n';
import { HttpLink, split } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { CommonPath } from '@commons/base-routes';
import moment from 'moment';
import { getHistory, setRedirectUrl } from './history';
import { isJsonString } from './string';

const TOKEN_KEY = env.tokenKey;
const TYPE_LOGIN = 'TYPE_LOGIN';
const CODE_LOGIN = 'CODE_LOGIN';
const DEVICE_ID = 'DEVICE_ID';
const LANGUAGE_KEY = env.languageKey;

let TOKEN: any = '';
let wsLink: any;
let alertChangeSchedule = false;

export enum TypeLogin {
  LOGIN_BY_CODE = 'LOGIN_BY_CODE',
  LOGIN_BY_USERNAME = 'LOGIN_BY_USERNAME',
}

export const setWsLink = (token?: string) => {
  const httpLink = new HttpLink({
    uri: env.apiEndPoint,
  });

  const ws = new WebSocketLink({
    uri: process.env.REACT_APP_API_WS || '',
    options: {
      reconnect: true,
      connectionParams: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    ws,
    httpLink,
  );
  wsLink = link;
};

export const getWsLink = () => {
  return wsLink;
};

export const setAuthData = (loginStorage: Jwt) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(loginStorage || {}));
  TOKEN = loginStorage.accessToken;
};

export const getAuthLocalData = () => {
  const loginStorage = parseTokenString(localStorage.getItem(TOKEN_KEY) || '');
  if (!tokenChecker(loginStorage)) {
    localStorage.removeItem(TOKEN_KEY);
    return null;
  }
  TOKEN = loginStorage?.accessToken;
  return loginStorage;
};

export const setDefaultLanguage = () => {
  const languageStorage = localStorage.getItem(LANGUAGE_KEY) || '';
  if (!languageStorage) {
    i18n.changeLanguage('no');
  }
};

export const getToken = () => {
  return `Bearer ${TOKEN}`;
};

export const getNewToken = () => {
  return `${TOKEN}`;
};

export const setToken = (token: string) => {
  TOKEN = token;
};

export const removeToken = () => {
  localStorage.removeItem(env.tokenKey);
  setToken('');
  setRedirectUrl('');
};

export const parseTokenString = (str: string) => {
  if (isJsonString(str)) {
    const authObject: Jwt = JSON.parse(str);
    return authObject;
  }
  return null;
};

export const tokenChecker = (loginStorage: Jwt | any) => {
  if (!loginStorage || !loginStorage.accessToken || loginStorage.expiresAt < moment().unix()) return false;
  return true;
};

export const refreshTokenChecker = (loginStorage: Jwt | any) => {
  if (!loginStorage || !loginStorage.refreshToken || loginStorage.refreshTokenExpiresAt < moment().unix()) return false;
  return true;
};

export const checkRefreshToken = () => {
  const loginStorage = parseTokenString(localStorage.getItem(TOKEN_KEY) || '');
  if (!refreshTokenChecker(loginStorage)) {
    removeToken();
    getHistory().replace(CommonPath.LOGIN_PATH);
  } else {
    return loginStorage;
  }
};

export const getLoginStorageData = () => {
  const loginStorage = parseTokenString(localStorage.getItem(TOKEN_KEY) || '');
  return loginStorage;
};

export const updateLocalToken = (userInfo: User) => {
  const loginStorage = parseTokenString(localStorage.getItem(TOKEN_KEY) || '');
  if (loginStorage && tokenChecker(loginStorage)) {
    // loginStorage['payload'] = {
    //   ...localStorage['payload'],
    //   ...userInfo,
    // };
    localStorage.setItem(TOKEN_KEY, JSON.stringify(loginStorage || {}));
    // TOKEN = loginStorage.token;
  }
};

export const getLocalLanguage = () => {
  return sessionStorage.getItem(env.languageKey) || 'en';
};

export const setAlertChangeSchedule = (status: boolean) => {
  alertChangeSchedule = status;
};

export const getAlertChangeSchedule = () => {
  return alertChangeSchedule;
};

export const setTypeLogin = (type: TypeLogin) => {
  localStorage.setItem(TYPE_LOGIN, type);
};

export const getTypeLogin = () => {
  return localStorage.getItem(TYPE_LOGIN);
};

export const removeTypeLogin = () => {
  localStorage.removeItem(TYPE_LOGIN);
};

export const saveCodeLogin = (code: string) => {
  localStorage.setItem(CODE_LOGIN, code);
};

export const getCodeLogin = () => {
  return localStorage.getItem(CODE_LOGIN);
};

export const removeCodeLogin = () => {
  localStorage.removeItem(CODE_LOGIN);
};

export const setDeviceId = (uuid: string) => {
  localStorage.setItem(DEVICE_ID, uuid);
};

export const getDeviceId = () => {
  return localStorage.getItem(DEVICE_ID);
};
