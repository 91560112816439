import { protectedComponent } from '@hoc/protectedComponent';
import ShopsPage from '@modules/Shops/index';

export const ShopsRouter = [
  {
    path: '/',
    exact: true,
    component: protectedComponent(ShopsPage),
  },
];
