import { AppError } from '@commons/types';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export interface LogoutInput {
  refreshToken: string;
}
export interface LogoutAction {
  type: typeof LOGOUT;
  payload: LogoutInput;
}
export interface LogoutSuccess {
  type: typeof LOGOUT_SUCCESS;
  payload: LogoutInput;
}
export interface LogoutError {
  type: typeof LOGOUT_ERROR;
  payload: AppError;
}
export interface LogoutState {
  loading: boolean;
}
export type LogoutActionTypes = LogoutAction | LogoutSuccess | LogoutError;
