import CustomUploadDragger from '@modules/Upload/CustomUploadDragger';
import { Form } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  name: any;
  fieldKey: any;
}

const ItemShopFormUpload = ({ fieldKey, name }: Props) => {
  const { t } = useTranslation(['hot-shop']);
  const [errorImage, setErrorImage] = useState<boolean>(false);
  return (
    <Form.Item
      name={[name, 'mediaId']}
      fieldKey={[fieldKey, 'mediaId']}
      label="Image"
      required
      labelAlign="left"
      rules={[
        {
          required: true,
          validator: async (_, names) => {
            if (names === undefined) {
              setErrorImage(true);
              return Promise.reject(new Error(t('validate.required')));
            }
            setErrorImage(false);
          },
        },
      ]}
    >
      <CustomUploadDragger
        errorRequired={errorImage}
        styleUploadContainer={{ width: 280, height: 130 }}
        styContainerView={{ width: 250 }}
      />
    </Form.Item>
  );
};

export default ItemShopFormUpload;
