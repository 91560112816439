import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AddFavoriteInput = {
  targetId: Scalars['ID'];
  type: FavoriteTypes;
};

export type AggregateRating = {
  __typename?: 'AggregateRating';
  avgRating: Scalars['Float'];
  countRating: Scalars['Float'];
  rating1: Scalars['Float'];
  rating2: Scalars['Float'];
  rating3: Scalars['Float'];
  rating4: Scalars['Float'];
  rating5: Scalars['Float'];
};

export type AmountPayment = {
  __typename?: 'AmountPayment';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type BasicAppSetting = {
  __typename?: 'BasicAppSetting';
  _id: Scalars['ID'];
  type: Scalars['String'];
  data: Scalars['Boolean'];
};

export enum BasicAppSettingType {
  SettingAllowSaveCreditCardInfo = 'SETTING_ALLOW_SAVE_CREDIT_CARD_INFO'
}

export type CancelOrderInput = {
  orderId: Scalars['ID'];
};

export type CardCancelInfor = {
  __typename?: 'CardCancelInfor';
  message?: Maybe<Scalars['String']>;
};

export type CardInfoInput = {
  name: Scalars['String'];
  number: Scalars['String'];
  expiry_year: Scalars['String'];
  expiry_month: Scalars['String'];
  cvv: Scalars['String'];
};

export type CashPaymentInfor = {
  __typename?: 'CashPaymentInfor';
  amount?: Maybe<Scalars['Float']>;
};

export type Category = {
  __typename?: 'Category';
  _id: Scalars['ID'];
  name: Scalars['String'];
  resId: Scalars['ID'];
  nameVi?: Maybe<Scalars['String']>;
  nameNor: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  iconId?: Maybe<Scalars['ID']>;
  foods?: Maybe<Array<Food>>;
  position?: Maybe<Scalars['Int']>;
  tax: CategoryTax;
};

export type CategoryTax = {
  __typename?: 'CategoryTax';
  web: TaxSettings;
  kiosk: TaxSettings;
  kasse: TaxSettings;
};

export type ChangePasswordInput = {
  /** current password */
  currentPassword: Scalars['String'];
  /** new password */
  newPassword: Scalars['String'];
};

/** Language in web client */
export enum ClientLangCode {
  En = 'En',
  No = 'No'
}

export enum ClientSystem {
  Kiosk = 'Kiosk',
  Web = 'Web',
  CloudPos = 'CloudPos',
  App = 'App'
}

export type ConnectEmail = {
  __typename?: 'ConnectEmail';
  email: Scalars['String'];
  codeId: Scalars['String'];
  expiresAt: Scalars['Float'];
};

export type ConnectEmailInput = {
  /** Email */
  email: Scalars['String'];
  /** Code id from connect email request */
  codeId: Scalars['String'];
  /** code from email */
  code: Scalars['String'];
  /** User password */
  password: Scalars['String'];
};

export type CreateOrderInput = {
  resId: Scalars['ID'];
  tableId?: Maybe<Scalars['ID']>;
  clientSystem?: Maybe<ClientSystem>;
  type: OrderType;
  /** Date for order, which is pickup later */
  pickupAt?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  location?: Maybe<OrderLocationInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  language?: Maybe<ClientLangCode>;
  isDelivery?: Maybe<Scalars['Boolean']>;
  taxType: TaxType;
  foods: Array<OrderFoodDataInput>;
  customFoods?: Maybe<Array<OrderCustomFoodInput>>;
  totalPrice: Scalars['Float'];
  paymentMethod: OrderPaymentMethod;
  fallBack: Scalars['String'];
  terminalId?: Maybe<Scalars['String']>;
  printerId?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
};

export type CustomerArrivedInput = {
  orderId: Scalars['String'];
  restId: Scalars['ID'];
};

export type DataCartPayment = {
  __typename?: 'DataCartPayment';
  paymentId?: Maybe<Scalars['String']>;
  order?: Maybe<OrderPayment>;
  orderItems?: Maybe<Array<OrderItem>>;
};


export enum DayType {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type DeActiveSumupCardInput = {
  resId: Scalars['ID'];
  cardToken: Scalars['String'];
};

export type DeliveryCost = {
  __typename?: 'DeliveryCost';
  tax?: Maybe<Scalars['Float']>;
  taxCost?: Maybe<Scalars['Float']>;
  costTotal?: Maybe<Scalars['Float']>;
};

export enum DinteroCardCallbackStatus {
  Authorized = 'AUTHORIZED',
  Failed = 'FAILED',
  OnHold = 'ON_HOLD'
}

export type DinteroCartItem = {
  __typename?: 'DinteroCartItem';
  id?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  line_id?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type DinteroPaymentInfor = {
  __typename?: 'DinteroPaymentInfor';
  id?: Maybe<Scalars['String']>;
  merchant_reference?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  status?: Maybe<DinteroCardCallbackStatus>;
  items?: Maybe<Array<DinteroCartItem>>;
  card?: Maybe<Scalars['JSON']>;
};

export type ErrorInfor = {
  __typename?: 'ErrorInfor';
  errorGroup?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  contextId?: Maybe<Scalars['String']>;
};

export type ExtraOption = {
  __typename?: 'ExtraOption';
  IDRef?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  titleNor: Scalars['String'];
  type: PropertyType;
  available: Scalars['Boolean'];
  /** This option is obligated or optional */
  isRequired?: Maybe<Scalars['Boolean']>;
  properties: Array<OptionProperty>;
};

export type ExtraOptionInput = {
  IDRef?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
  titleNor: Scalars['String'];
  type: PropertyType;
  available: Scalars['Boolean'];
  /** This option is obligated or optional */
  isRequired?: Maybe<Scalars['Boolean']>;
  properties: Array<OptionPropertyInput>;
};

export enum FavoriteTypes {
  Shop = 'SHOP',
  Food = 'FOOD'
}

export type FindFoodByIdsInput = {
  ids: Array<Scalars['ID']>;
};

export type FindFoodsInput = {
  /** Keyword to search */
  query?: Maybe<Scalars['String']>;
};

export type FindMyOrdersInput = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  /** Date to show list order in TV */
  showAtDate?: Maybe<Scalars['DateTime']>;
  statuses: Array<OrderStatus>;
};

export type FindNearMeRestaurantsArgs = {
  /** Keyword to search */
  location: LocationInput;
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Float']>;
};

export type FindRestaurantsInput = {
  /** Keyword to search */
  query?: Maybe<Scalars['String']>;
  showInWFO?: Maybe<Scalars['Boolean']>;
};

export type Food = {
  __typename?: 'Food';
  _id: Scalars['ID'];
  number: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** Price is used for order at home( included tax) */
  price?: Maybe<Scalars['Float']>;
  /** Price is used for order takeaway ( included tax ) */
  priceSecond?: Maybe<Scalars['Float']>;
  /** Food's department service */
  foodDepart?: Maybe<ShopDepart>;
  catId?: Maybe<Scalars['ID']>;
  resId?: Maybe<Scalars['ID']>;
  inPeakHours?: Maybe<Scalars['Boolean']>;
  nameVi?: Maybe<Scalars['String']>;
  nameNor?: Maybe<Scalars['String']>;
  /** Ingredient template Ids */
  IDRefs?: Maybe<Array<Scalars['String']>>;
  position?: Maybe<Scalars['Float']>;
  status: Scalars['Boolean'];
  /** The food is offered when user is in checkout screen. */
  recommended?: Maybe<Scalars['Boolean']>;
  mediaId?: Maybe<Scalars['ID']>;
  ingredients?: Maybe<Scalars['JSON']>;
  ingredientsNor?: Maybe<Scalars['JSON']>;
  options?: Maybe<Array<ExtraOption>>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Media>;
  category?: Maybe<Category>;
};

export type FoodFavoriteItem = {
  __typename?: 'FoodFavoriteItem';
  _id: Scalars['String'];
  targetId: Scalars['ID'];
  resId?: Maybe<Scalars['ID']>;
  type: FavoriteTypes;
  userId: Scalars['ID'];
  target?: Maybe<Food>;
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  ip?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  countryCapital?: Maybe<Scalars['String']>;
  stateProv?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type GetOrderInput = {
  _id?: Maybe<Scalars['ID']>;
  orderNumber?: Maybe<Scalars['String']>;
};

export type GetSumupCustomerDataInput = {
  resId: Scalars['ID'];
};

export type InfinitePaginationInput = {
  /** Limit of result */
  limit: Scalars['Int'];
  /** After object */
  after?: Maybe<Scalars['String']>;
};


export type Jwt = {
  __typename?: 'JWT';
  accessToken: Scalars['String'];
  expiresAt: Scalars['Float'];
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt: Scalars['Float'];
};

export type LocalMedia = {
  __typename?: 'LocalMedia';
  _id?: Maybe<Scalars['ID']>;
  path: Scalars['String'];
  fileName: Scalars['String'];
  type: UploadType;
  createdAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type LocationEntity = {
  __typename?: 'LocationEntity';
  /** Latitude */
  lat?: Maybe<Scalars['Float']>;
  /** Longitude */
  lng?: Maybe<Scalars['Float']>;
};

export type LocationInput = {
  /** Latitude */
  lat?: Maybe<Scalars['Float']>;
  /** Longitude */
  lng?: Maybe<Scalars['Float']>;
};

export type LoginWithAppleInput = {
  /** firebase device token */
  deviceToken?: Maybe<Scalars['String']>;
  /** Unique id for device */
  deviceId: Scalars['String'];
  /** Version of mobile app */
  appVersion: Scalars['String'];
  /** The authorization code token from Apple login */
  authorizationCode: Scalars['String'];
};

export type LoginWithEmailInput = {
  /** firebase device token */
  deviceToken?: Maybe<Scalars['String']>;
  /** Unique id for device */
  deviceId: Scalars['String'];
  /** Version of mobile app */
  appVersion: Scalars['String'];
  /** Login by email */
  email: Scalars['String'];
  /** Password */
  password: Scalars['String'];
};

export type LoginWithFacebookInput = {
  /** firebase device token */
  deviceToken?: Maybe<Scalars['String']>;
  /** Unique id for device */
  deviceId: Scalars['String'];
  /** Version of mobile app */
  appVersion: Scalars['String'];
  /** The access token from facebook login */
  token: Scalars['String'];
};

export type LoginWithGoogleInput = {
  /** firebase device token */
  deviceToken?: Maybe<Scalars['String']>;
  /** Unique id for device */
  deviceId: Scalars['String'];
  /** Version of mobile app */
  appVersion: Scalars['String'];
  /** The access token from Google login */
  token: Scalars['String'];
};

export type LoginWithWinSsoInput = {
  /** authorization code from sso */
  authorizationCode?: Maybe<Scalars['String']>;
  /** Unique id for device */
  deviceId: Scalars['String'];
};

export type Media = {
  __typename?: 'Media';
  _id?: Maybe<Scalars['ID']>;
  url: Scalars['String'];
  thumbnail: Scalars['String'];
  thumbnail2x?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateProfile: User;
  updateAvatar: Scalars['Boolean'];
  setRestaurantShowInApp?: Maybe<Scalars['Boolean']>;
  updateTopRestaurants: Array<TopRestaurant>;
  removeFavoriteItem: Scalars['Boolean'];
  addFavoriteItem: Scalars['Boolean'];
  updateBasicAppSetting?: Maybe<BasicAppSetting>;
  uploadFile: LocalMedia;
  cmsUploadFile: LocalMedia;
  rate: Scalars['Boolean'];
  unrate: Scalars['Boolean'];
  superAdminLoginByCode: Jwt;
  loginWithEmail: Jwt;
  loginWithFacebook: Jwt;
  loginWithGoogle: Jwt;
  loginWithApple: Jwt;
  /** User request create account by email. If account be able to create a code will be sent and submit by registerWithEmail mutation */
  requestRegisterWithEmail: RegisterByEmail;
  /** Register with email */
  registerWithEmail: Jwt;
  /** Refresh token */
  refreshToken: Jwt;
  requestResetPassword: RequestResetPassword;
  verifyOTPResetPassword: VerifiedOtpResetPassword;
  resetPassword: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  requestConnectEmail: ConnectEmail;
  verifyOTPConnectEmail: VerifiedOtpConnectEmail;
  connectEmail: Scalars['Boolean'];
  customerArrived?: Maybe<Order>;
  createOrder?: Maybe<Order>;
  cancelOrder?: Maybe<Scalars['Boolean']>;
  /** @deprecated Khong dung den */
  processSumupOrder?: Maybe<Scalars['Boolean']>;
  processSumupOrderByCreditToken?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpdateAvatarArgs = {
  file: Scalars['Upload'];
};


export type MutationSetRestaurantShowInAppArgs = {
  input: UpdateRestaurantInAppInput;
};


export type MutationUpdateTopRestaurantsArgs = {
  input: UpdateTopRestaurantInput;
};


export type MutationRemoveFavoriteItemArgs = {
  input: RemoveFavoriteInput;
};


export type MutationAddFavoriteItemArgs = {
  input: AddFavoriteInput;
};


export type MutationUpdateBasicAppSettingArgs = {
  input: UpdateBasicAppSettingInput;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationCmsUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationRateArgs = {
  input: RateInput;
};


export type MutationUnrateArgs = {
  input: UnrateInput;
};


export type MutationSuperAdminLoginByCodeArgs = {
  input: LoginWithWinSsoInput;
};


export type MutationLoginWithEmailArgs = {
  input: LoginWithEmailInput;
};


export type MutationLoginWithFacebookArgs = {
  input: LoginWithFacebookInput;
};


export type MutationLoginWithGoogleArgs = {
  input: LoginWithGoogleInput;
};


export type MutationLoginWithAppleArgs = {
  input: LoginWithAppleInput;
};


export type MutationRequestRegisterWithEmailArgs = {
  input: RequestRegisterWithEmailInput;
};


export type MutationRegisterWithEmailArgs = {
  input: RegisterWithEmailInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordWithEmailInput;
};


export type MutationVerifyOtpResetPasswordArgs = {
  input: VerifyOtpResetPasswordInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordWithEmailInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationRequestConnectEmailArgs = {
  input: RequestConnectEmailInput;
};


export type MutationVerifyOtpConnectEmailArgs = {
  input: VerifyOtpConnectEmailInput;
};


export type MutationConnectEmailArgs = {
  input: ConnectEmailInput;
};


export type MutationCustomerArrivedArgs = {
  input: CustomerArrivedInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCancelOrderArgs = {
  input: CancelOrderInput;
};


export type MutationProcessSumupOrderArgs = {
  input: ProcessSumupPaymentInput;
};


export type MutationProcessSumupOrderByCreditTokenArgs = {
  input: ProcessSumupOrderByCreditTokenInput;
};

export type OptionProperty = {
  __typename?: 'OptionProperty';
  title: Scalars['String'];
  titleNor: Scalars['String'];
  /** Price for takeaway */
  extraPrice?: Maybe<Scalars['Float']>;
  /** Price for at shop */
  extraPriceSecond?: Maybe<Scalars['Float']>;
  available: Scalars['Boolean'];
};

export type OptionPropertyInput = {
  title: Scalars['String'];
  titleNor: Scalars['String'];
  /** Price for takeaway */
  extraPrice?: Maybe<Scalars['Float']>;
  /** Price for restaurant */
  extraPriceSecond?: Maybe<Scalars['Float']>;
  available: Scalars['Boolean'];
};

export type Order = {
  __typename?: 'Order';
  _id: Scalars['ID'];
  resId: Scalars['ID'];
  tableId: Scalars['ID'];
  foods: Array<OrderFoodData>;
  foodsDetail?: Maybe<Array<Food>>;
  clientSystem: Scalars['String'];
  /** Type of order */
  type: OrderType;
  orderNumber: Scalars['String'];
  userOrderNumber?: Maybe<Scalars['String']>;
  simpleOrderNumber?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  language?: Maybe<ClientLangCode>;
  timeArrived?: Maybe<Scalars['String']>;
  preparationTime?: Maybe<Scalars['String']>;
  dateTimeConfirm?: Maybe<Scalars['String']>;
  totalPrice: Scalars['Float'];
  remainingPrice?: Maybe<Scalars['Float']>;
  paymentMethod?: Maybe<Array<OrderPaymentMethod>>;
  paymentStatus: PaymentStatus;
  payment?: Maybe<PaymentInit>;
  paymentInfor?: Maybe<PaymentInfor>;
  status: OrderStatus;
  /** Date for order, which is pickup later */
  pickupAt?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  deliveryCost?: Maybe<DeliveryCost>;
  location?: Maybe<LocationEntity>;
  dateTimeCompleted?: Maybe<Scalars['String']>;
  isDelevery?: Maybe<Scalars['Boolean']>;
  taxType?: Maybe<TaxType>;
  confirmedAt?: Maybe<Scalars['String']>;
  refundedAt?: Maybe<Scalars['String']>;
  wfoUserId?: Maybe<Scalars['ID']>;
  table?: Maybe<RestaurantTable>;
};

export type OrderCustomFoodInput = {
  name: Scalars['String'];
  price: Scalars['Float'];
  tax: Scalars['Float'];
  qty: Scalars['Float'];
};

export type OrderFoodData = {
  __typename?: 'OrderFoodData';
  _id: Scalars['ID'];
  code?: Maybe<Scalars['String']>;
  qty: Scalars['Float'];
  price?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  tax?: Maybe<TaxOrder>;
  taxType?: Maybe<TaxType>;
  isReady?: Maybe<Scalars['Boolean']>;
  extraOptions?: Maybe<Array<ExtraOption>>;
};

export type OrderFoodDataInput = {
  _id: Scalars['ID'];
  qty: Scalars['Float'];
  note?: Maybe<Scalars['String']>;
  taxType?: Maybe<TaxType>;
  extraOptions?: Maybe<Array<ExtraOptionInput>>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  reference?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  unitNetPrice?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmount?: Maybe<Scalars['Float']>;
  grossTotalAmount?: Maybe<Scalars['Float']>;
  netTotalAmount?: Maybe<Scalars['Float']>;
};

export type OrderLocationInput = {
  name: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type OrderPayment = {
  __typename?: 'OrderPayment';
  reference?: Maybe<Scalars['String']>;
  amount?: Maybe<AmountPayment>;
  orderItems?: Maybe<Array<OrderItem>>;
};

export enum OrderPaymentMethod {
  Card = 'CARD',
  Vipps = 'VIPPS',
  AtCounter = 'AT_COUNTER',
  Terminal = 'TERMINAL',
  TerminalSumup = 'TERMINAL_SUMUP',
  Postpaid = 'POSTPAID'
}

export enum OrderStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Confirmed = 'CONFIRMED',
  Cooking = 'COOKING',
  Ready = 'READY',
  Finish = 'FINISH',
  Cancelled = 'CANCELLED',
  Refunded = 'REFUNDED'
}

export enum OrderType {
  PrepaidOrder = 'PREPAID_ORDER',
  PostpaidOrder = 'POSTPAID_ORDER'
}

export type PageDetail = {
  __typename?: 'PageDetail';
  id: Scalars['String'];
  content: Scalars['String'];
};

export type PaymentInfor = {
  __typename?: 'PaymentInfor';
  vipps?: Maybe<VippsPaymentInfor>;
  card?: Maybe<DinteroPaymentInfor>;
  cash?: Maybe<CashPaymentInfor>;
  terminal?: Maybe<TerminalPaymentInfor>;
  sumup?: Maybe<SumUpPaymentInfor>;
  terminalSumUp?: Maybe<TerminalSumUpPaymentInfo>;
};

export type PaymentInit = {
  __typename?: 'PaymentInit';
  /** Url response */
  url: Scalars['String'];
  /** Payment id */
  paymentId?: Maybe<Scalars['String']>;
  /** Payment infor */
  paymentInfor?: Maybe<PaymentInfor>;
  /** Payment id */
  type?: Maybe<ServiceGateway>;
  /** Use for sumUp payment gate way */
  sumUpToken?: Maybe<SumUpTerminalToken>;
};

export enum PaymentStatus {
  Pending = 'PENDING',
  PaidApart = 'PAID_APART',
  Confirmed = 'CONFIRMED',
  Cancelled = 'CANCELLED',
  Success = 'SUCCESS'
}

export type ProcessSumupOrderByCreditTokenInput = {
  resId: Scalars['ID'];
  orderId: Scalars['ID'];
  cardToken?: Maybe<Scalars['String']>;
};

export type ProcessSumupPaymentInput = {
  orderId: Scalars['ID'];
  cardInfo: CardInfoInput;
};

export enum PropertyType {
  AtLeast = 'AT_LEAST',
  Many = 'MANY'
}

export type Query = {
  __typename?: 'Query';
  restaurant?: Maybe<Restaurant>;
  restaurantGetSumupToken?: Maybe<Scalars['String']>;
  findRestaurants?: Maybe<Array<Restaurant>>;
  findNearMeRestaurants?: Maybe<Array<Restaurant>>;
  myFavoriteRestaurants?: Maybe<Array<RestaurantFavoriteItem>>;
  topRatingRestaurants?: Maybe<Array<Restaurant>>;
  restaurantFoods?: Maybe<Array<Category>>;
  findAllRestaurants?: Maybe<ResRestaurant>;
  getAllTopRestaurants: Array<TopRestaurant>;
  getBasicAppSettings?: Maybe<Array<BasicAppSetting>>;
  myFavoriteFoods: Array<FoodFavoriteItem>;
  findFoodByIds: Array<Food>;
  restaurantRatings: Array<Rating>;
  userRatings: Array<Rating>;
  me: User;
  superAdmin: SuperAdmin;
  getGeoLocationByIp?: Maybe<GeoLocation>;
  findMyOrders?: Maybe<Array<Order>>;
  findOrder?: Maybe<Order>;
  getSumupCustomerData?: Maybe<SumupCustomer>;
  deActiveSumupCard?: Maybe<Scalars['Boolean']>;
  getPageDetail?: Maybe<PageDetail>;
};


export type QueryRestaurantArgs = {
  input: QueryRecordDetailInput;
};


export type QueryRestaurantGetSumupTokenArgs = {
  input: QueryRecordDetailInput;
};


export type QueryFindRestaurantsArgs = {
  pagination: InfinitePaginationInput;
  filter?: Maybe<FindRestaurantsInput>;
};


export type QueryFindNearMeRestaurantsArgs = {
  input: FindNearMeRestaurantsArgs;
};


export type QueryMyFavoriteRestaurantsArgs = {
  pagination: InfinitePaginationInput;
};


export type QueryTopRatingRestaurantsArgs = {
  input: TopRatingRestaurantsArgs;
};


export type QueryRestaurantFoodsArgs = {
  input: QueryRecordDetailInput;
};


export type QueryFindAllRestaurantsArgs = {
  filter?: Maybe<FindRestaurantsInput>;
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Float']>;
};


export type QueryMyFavoriteFoodsArgs = {
  filter?: Maybe<FindFoodsInput>;
  pagination: InfinitePaginationInput;
  resId: Scalars['ID'];
};


export type QueryFindFoodByIdsArgs = {
  input: FindFoodByIdsInput;
};


export type QueryRestaurantRatingsArgs = {
  pagination: InfinitePaginationInput;
  filter: RestaurantRatingsInput;
};


export type QueryUserRatingsArgs = {
  pagination: InfinitePaginationInput;
  filter: UserRatingsInput;
};


export type QueryFindMyOrdersArgs = {
  pagination: InfinitePaginationInput;
  filter: FindMyOrdersInput;
  resId: Scalars['ID'];
};


export type QueryFindOrderArgs = {
  input: GetOrderInput;
};


export type QueryGetSumupCustomerDataArgs = {
  input: GetSumupCustomerDataInput;
};


export type QueryDeActiveSumupCardArgs = {
  input: DeActiveSumupCardInput;
};


export type QueryGetPageDetailArgs = {
  input: QueryPageDetailBySlug;
};

export type QueryPageDetailBySlug = {
  slug: SiteSlugs;
};

export type QueryRecordDetailInput = {
  _id: Scalars['ID'];
};

export type RateInput = {
  restaurantId: Scalars['ID'];
  rate: Scalars['Float'];
};

export type Rating = {
  __typename?: 'Rating';
  _id: Scalars['String'];
  restaurantId: Scalars['ID'];
  userId: Scalars['ID'];
  rateTime: Scalars['Float'];
  ratingValue: Scalars['Float'];
};

export type RefreshTokenInput = {
  /** Refresh token */
  refreshToken: Scalars['String'];
};

export type RegisterByEmail = {
  __typename?: 'RegisterByEmail';
  email: Scalars['String'];
  codeId: Scalars['String'];
  expiresAt: Scalars['Float'];
};

export type RegisterWithEmailInput = {
  /** Login by email */
  email: Scalars['String'];
  /** Password */
  password: Scalars['String'];
  /** Full name */
  fullName: Scalars['String'];
  /** code id from register request */
  codeId: Scalars['String'];
  /** code from email */
  code: Scalars['String'];
  /** Unique id for device */
  deviceId: Scalars['String'];
};

export type RemoveFavoriteInput = {
  targetId: Scalars['ID'];
  type: FavoriteTypes;
};

export type RequestConnectEmailInput = {
  /** Email */
  email: Scalars['String'];
};

export type RequestRegisterWithEmailInput = {
  /** Login by email */
  email: Scalars['String'];
  /** Password */
  password: Scalars['String'];
  /** Full name */
  fullName: Scalars['String'];
};

export type RequestResetPassword = {
  __typename?: 'RequestResetPassword';
  email: Scalars['String'];
  codeId: Scalars['String'];
  expiresAt: Scalars['Float'];
};

export type RequestResetPasswordWithEmailInput = {
  /** Email */
  email: Scalars['String'];
};

export type ResRestaurant = {
  __typename?: 'ResRestaurant';
  total: Scalars['Float'];
  data: Array<Restaurant>;
};

export type ResetPasswordWithEmailInput = {
  /** Email */
  email: Scalars['String'];
  /** code id from register request */
  codeId: Scalars['String'];
  /** code from email */
  code: Scalars['String'];
  /** New Password */
  password: Scalars['String'];
};

export type Restaurant = {
  __typename?: 'Restaurant';
  _id: Scalars['ID'];
  /** name restaurant */
  name: Scalars['String'];
  /** Organization number */
  tables?: Maybe<Array<RestaurantTable>>;
  /** address restaurant */
  address?: Maybe<Scalars['String']>;
  /** domain restaurant */
  domain: Scalars['String'];
  /** Description restaurant */
  description?: Maybe<Scalars['String']>;
  /** Description restaurant */
  descriptionNor?: Maybe<Scalars['String']>;
  /** phone number restaurant */
  phoneNumber?: Maybe<Scalars['String']>;
  settings?: Maybe<RestaurantSettings>;
  mediaId?: Maybe<Scalars['ID']>;
  backgroundMobileId?: Maybe<Scalars['ID']>;
  backgroundId?: Maybe<Scalars['ID']>;
  /** domain client restaurant */
  clientDomain?: Maybe<Scalars['String']>;
  isFavorite?: Maybe<Scalars['Boolean']>;
  avgRating?: Maybe<Scalars['Float']>;
  showInWFO?: Maybe<Scalars['Boolean']>;
  /** link facebook restaurant */
  facebook?: Maybe<Scalars['String']>;
  /** link instagram restaurant */
  instagram?: Maybe<Scalars['String']>;
  /** link tiktok restaurant */
  tiktok?: Maybe<Scalars['String']>;
  /** link youtube restaurant */
  youtube?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['JSON']>;
  useTableToOrderInApp?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Media>;
  background?: Maybe<Media>;
  backgroundMobile?: Maybe<Media>;
  rating?: Maybe<AggregateRating>;
  myRating?: Maybe<Rating>;
  favoriteFoods?: Maybe<Array<Food>>;
};

export type RestaurantCloseTime = {
  __typename?: 'RestaurantCloseTime';
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type RestaurantFavoriteItem = {
  __typename?: 'RestaurantFavoriteItem';
  _id: Scalars['ID'];
  targetId: Scalars['ID'];
  type: FavoriteTypes;
  userId: Scalars['ID'];
  target?: Maybe<Restaurant>;
};

export type RestaurantOpenTime = {
  __typename?: 'RestaurantOpenTime';
  day?: Maybe<DayType>;
  timeOpen?: Maybe<Scalars['String']>;
  timeClose?: Maybe<Scalars['String']>;
  preparingTime?: Maybe<Scalars['Float']>;
};

export type RestaurantOrderType = {
  __typename?: 'RestaurantOrderType';
  dinein?: Maybe<Scalars['Float']>;
  takeaway?: Maybe<Scalars['Float']>;
};

export type RestaurantPeakHour = {
  __typename?: 'RestaurantPeakHour';
  day?: Maybe<DayType>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  preparingTime?: Maybe<Scalars['Float']>;
};

export type RestaurantRatingsInput = {
  restaurantId: Scalars['ID'];
};

export type RestaurantSettingPaymentMethods = {
  __typename?: 'RestaurantSettingPaymentMethods';
  sumup?: Maybe<Scalars['Boolean']>;
  vipps?: Maybe<Scalars['Boolean']>;
};

export type RestaurantSettings = {
  __typename?: 'RestaurantSettings';
  /** Restaurant's tax */
  tax?: Maybe<TaxRestaurantRes>;
  timeOpen?: Maybe<Array<RestaurantOpenTime>>;
  timeClose?: Maybe<Array<RestaurantCloseTime>>;
  peakHour?: Maybe<Array<RestaurantPeakHour>>;
  orderType?: Maybe<RestaurantOrderType>;
  location?: Maybe<GeoLocation>;
  isTemporaryClose?: Maybe<Scalars['Boolean']>;
  paymentMethod?: Maybe<RestaurantSettingPaymentMethods>;
  isAllowSumupCard?: Maybe<Scalars['Boolean']>;
  isHolidayClose?: Maybe<Scalars['Boolean']>;
};

export type RestaurantTable = {
  __typename?: 'RestaurantTable';
  _id: Scalars['ID'];
  /** Id of restaurant */
  resId: Scalars['String'];
  /** Name of table */
  name: Scalars['String'];
  numberOfSeats: Scalars['Int'];
};

export enum ServiceGateway {
  Dintero = 'Dintero',
  SumUp = 'SumUp',
  Vipps = 'Vipps'
}

/** Depart services */
export enum ShopDepart {
  SushiDepart = 'SUSHI_DEPART',
  HotFoodDepart = 'HOT_FOOD_DEPART',
  BeverageDepart = 'BEVERAGE_DEPART'
}

export enum SiteSlugs {
  AboutUs = 'ABOUT_US',
  Terms = 'TERMS',
  Privacy = 'PRIVACY',
  PaymentInstrument = 'PAYMENT_INSTRUMENT'
}

export enum SumUpCallbackStatus {
  Pending = 'PENDING',
  Failed = 'FAILED',
  Paid = 'PAID'
}

export type SumUpCardObject = {
  __typename?: 'SumUpCardObject';
  last_4_digits?: Maybe<Scalars['String']>;
  type?: Maybe<SumupCardType>;
};

export type SumUpPaymentInfor = {
  __typename?: 'SumUpPaymentInfor';
  checkoutReference?: Maybe<Scalars['String']>;
  checkoutId?: Maybe<Scalars['String']>;
  status?: Maybe<SumUpCallbackStatus>;
  amount?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
  transactionCode?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  purpose?: Maybe<Scalars['String']>;
  transactions?: Maybe<SumUpTransaction>;
};

export type SumUpTransaction = {
  __typename?: 'SumUpTransaction';
  id?: Maybe<Scalars['String']>;
  transaction_code?: Maybe<Scalars['String']>;
  merchant_code?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  vat_amount?: Maybe<Scalars['String']>;
  tip_amount?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  status?: Maybe<SumupStatus>;
  payment_type?: Maybe<Scalars['String']>;
  entry_mode?: Maybe<Scalars['String']>;
  installments_count?: Maybe<Scalars['String']>;
  internal_id?: Maybe<Scalars['String']>;
  payout_plan?: Maybe<Scalars['String']>;
  card?: Maybe<SumUpCardObject>;
  payout_type?: Maybe<Scalars['String']>;
  product_summary?: Maybe<Scalars['String']>;
  verification_method?: Maybe<Scalars['String']>;
  tax_enabled?: Maybe<Scalars['Boolean']>;
  auth_code?: Maybe<Scalars['Boolean']>;
};

export type SumupCard = {
  __typename?: 'SumupCard';
  token?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  card?: Maybe<SumupCardCardInfo>;
  created_at?: Maybe<Scalars['String']>;
};

export type SumupCardCardInfo = {
  __typename?: 'SumupCardCardInfo';
  last_4_digits?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum SumupCardType {
  Amex = 'AMEX',
  Cup = 'CUP',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Elo = 'ELO',
  Elv = 'ELV',
  Hipercard = 'HIPERCARD',
  Jcb = 'JCB',
  Maestro = 'MAESTRO',
  Mastercard = 'MASTERCARD',
  Visa = 'VISA',
  VisaElectron = 'VISA_ELECTRON',
  VisaVpay = 'VISA_VPAY',
  Unknown = 'UNKNOWN'
}

export type SumupCustomer = {
  __typename?: 'SumupCustomer';
  /** sumup customer id */
  customer_id: Scalars['String'];
  personal_details?: Maybe<SumupCustomerPersonalDetail>;
  card?: Maybe<Array<SumupCard>>;
};

export type SumupCustomerPersonalDetail = {
  __typename?: 'SumupCustomerPersonalDetail';
  /** sumup customer id */
  email: Scalars['String'];
};

export enum SumupStatus {
  Successful = 'SUCCESSFUL',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type SuperAdmin = {
  __typename?: 'SuperAdmin';
  _id: Scalars['String'];
  fullName: Scalars['String'];
  phoneNumber: Scalars['String'];
  username: Scalars['String'];
};

export type TaxOrder = {
  __typename?: 'TaxOrder';
  type?: Maybe<TaxType>;
  taxPercent: Scalars['Float'];
  taxPrice: Scalars['Float'];
};

export type TaxRestaurantRes = {
  __typename?: 'TaxRestaurantRes';
  taxIn?: Maybe<Scalars['Float']>;
  taxOut?: Maybe<Scalars['Float']>;
  taxDelivery?: Maybe<Scalars['Float']>;
};

export type TaxSettings = {
  __typename?: 'TaxSettings';
  dineIn: Scalars['Float'];
  takeaway: Scalars['Float'];
};

export enum TaxType {
  TaxIn = 'taxIn',
  TaxOut = 'taxOut',
  TaxDelivery = 'taxDelivery'
}

export type TerminalPaymentInfor = {
  __typename?: 'TerminalPaymentInfor';
  result?: Maybe<Array<TerminalTracsaction>>;
  failure?: Maybe<TerminalTracsaction>;
  amount?: Maybe<Scalars['Float']>;
};

export type TerminalSumUpPaymentInfo = {
  __typename?: 'TerminalSumUpPaymentInfo';
  transactionCode?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  transactions?: Maybe<SumUpTransaction>;
  foreignTransactionId?: Maybe<Scalars['String']>;
  dataPayment?: Maybe<SumUpTransaction>;
};

export type TerminalTracsaction = {
  __typename?: 'TerminalTracsaction';
  error?: Maybe<Scalars['String']>;
  transactionId: Scalars['String'];
  cardNumber: Scalars['String'];
  cardType: Scalars['String'];
  timeStamp: Scalars['String'];
  localModeEventArgs: Array<DinteroCartItem>;
};

export type TopRatingRestaurantsArgs = {
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Float']>;
};

export type TopRestaurant = {
  __typename?: 'TopRestaurant';
  /** Id of restaurant */
  restaurantId: Scalars['String'];
  /** Id of media */
  mediaId: Scalars['String'];
  media?: Maybe<LocalMedia>;
  restaurant?: Maybe<Restaurant>;
};

export type TransactionInfor = {
  __typename?: 'TransactionInfor';
  amount?: Maybe<Scalars['Float']>;
  timeStamp?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
};

export type UnrateInput = {
  restaurantId: Scalars['ID'];
};

export type UpdateBasicAppSettingInput = {
  type: BasicAppSettingType;
  value: Scalars['Boolean'];
};

export type UpdateProfileInput = {
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type UpdateRestaurantInAppInput = {
  restaurantIds: Array<Scalars['ID']>;
  showInApp: Scalars['Boolean'];
};

export type UpdateTopRestaurantInput = {
  /** Keyword to search */
  restaurants: Array<UpdateTopRestaurantItemInput>;
};

export type UpdateTopRestaurantItemInput = {
  restaurantId: Scalars['String'];
  mediaId: Scalars['String'];
};


export enum UploadType {
  Photo = 'PHOTO'
}

export type User = {
  __typename?: 'User';
  _id: Scalars['String'];
  facebookEmail?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  appleEmail?: Maybe<Scalars['String']>;
  initialProvider: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  avatarPath?: Maybe<Scalars['String']>;
  sumupCustomer?: Maybe<SumupCustomer>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  hasPassword: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
};

export type UserRatingsInput = {
  userId: Scalars['ID'];
};

export type VerifiedOtpConnectEmail = {
  __typename?: 'VerifiedOTPConnectEmail';
  codeId: Scalars['String'];
  expiresAt: Scalars['Float'];
};

export type VerifiedOtpResetPassword = {
  __typename?: 'VerifiedOTPResetPassword';
  codeId: Scalars['String'];
  expiresAt: Scalars['Float'];
};

export type VerifyOtpConnectEmailInput = {
  /** code id from connect email request */
  codeId: Scalars['String'];
  /** code from email */
  code: Scalars['String'];
};

export type VerifyOtpResetPasswordInput = {
  /** code id from register request */
  codeId: Scalars['String'];
  /** code from email */
  code: Scalars['String'];
};

export type VippsCancelInfor = {
  __typename?: 'VippsCancelInfor';
  message?: Maybe<Scalars['String']>;
};

export type VippsPaymentInfor = {
  __typename?: 'VippsPaymentInfor';
  merchantSerialNumber?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  transactionInfo?: Maybe<TransactionInfor>;
  errorInfor?: Maybe<ErrorInfor>;
  cancelInfor?: Maybe<VippsCancelInfor>;
};

export type SumUpTerminalToken = {
  __typename?: 'sumUpTerminalToken';
  access_token: Scalars['String'];
  token_type: Scalars['String'];
  expires_in: Scalars['Float'];
  scope: Scalars['String'];
};

export type LoginWithEmailMutationVariables = Exact<{
  input: LoginWithEmailInput;
}>;


export type LoginWithEmailMutation = (
  { __typename?: 'Mutation' }
  & { loginWithEmail: (
    { __typename?: 'JWT' }
    & Pick<Jwt, 'accessToken' | 'expiresAt' | 'refreshToken' | 'refreshTokenExpiresAt'>
  ) }
);

export type SuperAdminLoginByCodeMutationVariables = Exact<{
  input: LoginWithWinSsoInput;
}>;


export type SuperAdminLoginByCodeMutation = (
  { __typename?: 'Mutation' }
  & { superAdminLoginByCode: (
    { __typename?: 'JWT' }
    & Pick<Jwt, 'accessToken' | 'expiresAt' | 'refreshToken' | 'refreshTokenExpiresAt'>
  ) }
);

export type RefreshTokenMutationMutationVariables = Exact<{
  input: RefreshTokenInput;
}>;


export type RefreshTokenMutationMutation = (
  { __typename?: 'Mutation' }
  & { refreshToken: (
    { __typename?: 'JWT' }
    & Pick<Jwt, 'accessToken' | 'expiresAt' | 'refreshToken' | 'refreshTokenExpiresAt'>
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type SuperAdminQueryVariables = Exact<{ [key: string]: never; }>;


export type SuperAdminQuery = (
  { __typename?: 'Query' }
  & { superAdmin: (
    { __typename?: 'SuperAdmin' }
    & Pick<SuperAdmin, '_id' | 'fullName' | 'phoneNumber' | 'username'>
  ) }
);

export type UpdateTopRestaurantsMutationVariables = Exact<{
  input: UpdateTopRestaurantInput;
}>;


export type UpdateTopRestaurantsMutation = (
  { __typename?: 'Mutation' }
  & { updateTopRestaurants: Array<(
    { __typename?: 'TopRestaurant' }
    & Pick<TopRestaurant, 'restaurantId'>
  )> }
);

export type GetAllTopRestaurantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllTopRestaurantsQuery = (
  { __typename?: 'Query' }
  & { getAllTopRestaurants: Array<(
    { __typename?: 'TopRestaurant' }
    & Pick<TopRestaurant, 'mediaId' | 'restaurantId'>
    & { media?: Maybe<(
      { __typename?: 'LocalMedia' }
      & Pick<LocalMedia, '_id' | 'path' | 'fileName' | 'type' | 'createdAt' | 'url'>
    )>, restaurant?: Maybe<(
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, 'name' | 'address' | 'domain' | 'description'>
    )> }
  )> }
);

export type UpdateBasicAppSettingMutationVariables = Exact<{
  input: UpdateBasicAppSettingInput;
}>;


export type UpdateBasicAppSettingMutation = (
  { __typename?: 'Mutation' }
  & { updateBasicAppSetting?: Maybe<(
    { __typename?: 'BasicAppSetting' }
    & Pick<BasicAppSetting, '_id' | 'type' | 'data'>
  )> }
);

export type GetBasicAppSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBasicAppSettingsQuery = (
  { __typename?: 'Query' }
  & { getBasicAppSettings?: Maybe<Array<(
    { __typename?: 'BasicAppSetting' }
    & Pick<BasicAppSetting, '_id' | 'type' | 'data'>
  )>> }
);

export type SetRestaurantShowInAppMutationVariables = Exact<{
  input: UpdateRestaurantInAppInput;
}>;


export type SetRestaurantShowInAppMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setRestaurantShowInApp'>
);

export type FindAllRestaurantsQueryVariables = Exact<{
  filter?: Maybe<FindRestaurantsInput>;
  limit: Scalars['Int'];
  page?: Maybe<Scalars['Float']>;
}>;


export type FindAllRestaurantsQuery = (
  { __typename?: 'Query' }
  & { findAllRestaurants?: Maybe<(
    { __typename?: 'ResRestaurant' }
    & Pick<ResRestaurant, 'total'>
    & { data: Array<(
      { __typename?: 'Restaurant' }
      & Pick<Restaurant, '_id' | 'name' | 'showInWFO'>
      & { media?: Maybe<(
        { __typename?: 'Media' }
        & Pick<Media, '_id' | 'url' | 'thumbnail' | 'thumbnail2x'>
      )> }
    )> }
  )> }
);

export type UploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { cmsUploadFile: (
    { __typename?: 'LocalMedia' }
    & Pick<LocalMedia, '_id' | 'path' | 'fileName' | 'type' | 'createdAt' | 'url'>
  ) }
);


export const LoginWithEmailDocument = gql`
    mutation LoginWithEmail($input: LoginWithEmailInput!) {
  loginWithEmail(input: $input) {
    accessToken
    expiresAt
    refreshToken
    refreshTokenExpiresAt
  }
}
    `;
export const SuperAdminLoginByCodeDocument = gql`
    mutation SuperAdminLoginByCode($input: LoginWithWinSSOInput!) {
  superAdminLoginByCode(input: $input) {
    accessToken
    expiresAt
    refreshToken
    refreshTokenExpiresAt
  }
}
    `;
export const RefreshTokenMutationDocument = gql`
    mutation RefreshTokenMutation($input: RefreshTokenInput!) {
  refreshToken(input: $input) {
    accessToken
    expiresAt
    refreshToken
    refreshTokenExpiresAt
  }
}
    `;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export const SuperAdminDocument = gql`
    query SuperAdmin {
  superAdmin {
    _id
    fullName
    phoneNumber
    username
  }
}
    `;
export const UpdateTopRestaurantsDocument = gql`
    mutation UpdateTopRestaurants($input: UpdateTopRestaurantInput!) {
  updateTopRestaurants(input: $input) {
    restaurantId
  }
}
    `;
export const GetAllTopRestaurantsDocument = gql`
    query GetAllTopRestaurants {
  getAllTopRestaurants {
    media {
      _id
      path
      fileName
      type
      createdAt
      url
    }
    mediaId
    restaurantId
    restaurant {
      name
      address
      domain
      description
    }
  }
}
    `;
export const UpdateBasicAppSettingDocument = gql`
    mutation UpdateBasicAppSetting($input: UpdateBasicAppSettingInput!) {
  updateBasicAppSetting(input: $input) {
    _id
    type
    data
  }
}
    `;
export const GetBasicAppSettingsDocument = gql`
    query GetBasicAppSettings {
  getBasicAppSettings {
    _id
    type
    data
  }
}
    `;
export const SetRestaurantShowInAppDocument = gql`
    mutation SetRestaurantShowInApp($input: UpdateRestaurantInAppInput!) {
  setRestaurantShowInApp(input: $input)
}
    `;
export const FindAllRestaurantsDocument = gql`
    query FindAllRestaurants($filter: FindRestaurantsInput, $limit: Int!, $page: Float) {
  findAllRestaurants(filter: $filter, limit: $limit, page: $page) {
    total
    data {
      _id
      name
      showInWFO
      media {
        _id
        url
        thumbnail
        thumbnail2x
      }
    }
  }
}
    `;
export const UploadFileDocument = gql`
    mutation UploadFile($file: Upload!) {
  cmsUploadFile(file: $file) {
    _id
    path
    fileName
    type
    createdAt
    url
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    LoginWithEmail(variables: LoginWithEmailMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoginWithEmailMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoginWithEmailMutation>(LoginWithEmailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'LoginWithEmail');
    },
    SuperAdminLoginByCode(variables: SuperAdminLoginByCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SuperAdminLoginByCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SuperAdminLoginByCodeMutation>(SuperAdminLoginByCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SuperAdminLoginByCode');
    },
    RefreshTokenMutation(variables: RefreshTokenMutationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RefreshTokenMutationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RefreshTokenMutationMutation>(RefreshTokenMutationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RefreshTokenMutation');
    },
    logout(variables?: LogoutMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LogoutMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LogoutMutation>(LogoutDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'logout');
    },
    SuperAdmin(variables?: SuperAdminQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SuperAdminQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SuperAdminQuery>(SuperAdminDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SuperAdmin');
    },
    UpdateTopRestaurants(variables: UpdateTopRestaurantsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateTopRestaurantsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateTopRestaurantsMutation>(UpdateTopRestaurantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTopRestaurants');
    },
    GetAllTopRestaurants(variables?: GetAllTopRestaurantsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetAllTopRestaurantsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAllTopRestaurantsQuery>(GetAllTopRestaurantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAllTopRestaurants');
    },
    UpdateBasicAppSetting(variables: UpdateBasicAppSettingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateBasicAppSettingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateBasicAppSettingMutation>(UpdateBasicAppSettingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateBasicAppSetting');
    },
    GetBasicAppSettings(variables?: GetBasicAppSettingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetBasicAppSettingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBasicAppSettingsQuery>(GetBasicAppSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBasicAppSettings');
    },
    SetRestaurantShowInApp(variables: SetRestaurantShowInAppMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetRestaurantShowInAppMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SetRestaurantShowInAppMutation>(SetRestaurantShowInAppDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'SetRestaurantShowInApp');
    },
    FindAllRestaurants(variables: FindAllRestaurantsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FindAllRestaurantsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FindAllRestaurantsQuery>(FindAllRestaurantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'FindAllRestaurants');
    },
    UploadFile(variables: UploadFileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UploadFileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UploadFileMutation>(UploadFileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UploadFile');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;