// import * as apis from '@modules/Auth/services/apis';
import { ResetPasswordAction } from '../action-types';
import { put } from 'redux-saga/effects';
import { resetPasswordSuccess, resetPasswordError } from '../actions';
import { NotificationSuccess } from '@commons/components/Notification';
import { getHistory } from '@/helpers/history';
import { __ } from '@helpers/string';
import { CommonPath } from '@commons/base-routes';

export function* resetPasswordAsync(action: ResetPasswordAction) {
  try {
    // yield apis.resetPassword(action.payload);
    yield put(resetPasswordSuccess(action.payload));
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}
export function resetPasswordSuccessAsync() {
  NotificationSuccess(__('Thông báo'), __('Mật khẩu đã được thay đổi thành công'));
  getHistory().push(CommonPath.LOGIN_PATH);
}
