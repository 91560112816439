import { UpdateTopRestaurantItemInput } from '@/graphql/generated/graphql';
import CardForm from '@commons/components/Card/CardForm';
import FormSkeleton from '@commons/components/Skeletons/FormSkeleton';
import { getHistory } from '@helpers/history';
import { getHotShopsUrl } from '@helpers/url';
import ShopForm from '@modules/HotShop/components/shopForm';
import useGetAllTopRestaurant from '@modules/HotShop/hook/useGetAllTopRestaurant';
import useUpdateTopRestaurant from '@modules/HotShop/hook/useUpdateTopRestaurant';
import { Button } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const NewHotShop = () => {
  const { t } = useTranslation(['hot-shop']);
  const ref = useRef<any>(null);
  const { isLoading, mutate: updateTopRestaurant, isSuccess } = useUpdateTopRestaurant();
  const { isLoading: loadingHotshop, data: listHotShop } = useGetAllTopRestaurant();

  const onUpdateTopRestaurant = (values?: UpdateTopRestaurantItemInput[]) => {
    if (values) {
      updateTopRestaurant({
        input: {
          restaurants: values.map((item: any) => {
            return { ...item, mediaId: item.mediaId?._id };
          }),
        },
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      getHistory().push(getHotShopsUrl());
    }
  }, [isSuccess]);

  return (
    <>
      <div className="form-header-custom d-flex justify-content-between  align-items-center mb-4">
        <div />
        <div className="d-flex justify-content-between  align-items-center">
          <Button
            onClick={() => {
              getHistory().push(getHotShopsUrl());
            }}
            className="btn-cancel mr-3"
          >
            {t('cancel')}
          </Button>
          <Button
            loading={isLoading}
            className="btn-submit-form"
            onClick={() => {
              ref?.current?.onSubmit();
            }}
          >
            {t('submit')}
          </Button>
        </div>
      </div>
      <CardForm>
        <FormSkeleton loading={loadingHotshop}>
          <ShopForm ref={ref} onCreate={onUpdateTopRestaurant} listHotShop={listHotShop?.getAllTopRestaurants as any} />
        </FormSkeleton>
      </CardForm>
    </>
  );
};

export default NewHotShop;
