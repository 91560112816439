export const getImageUrl = (uri: string | undefined) => {
  if (!uri) return 'https://upload.wikimedia.org/wikipedia/commons/6/6b/Picture_icon_BLACK.svg';
  return `${process.env.REACT_APP_ASSETS_END_POINT}/${uri}`;
};

export const getImageUrlWinBooking = (uri: string | undefined) => {
  if (!uri) return 'https://upload.wikimedia.org/wikipedia/commons/6/6b/Picture_icon_BLACK.svg';
  return `${process.env.REACT_APP_ASSETS_END_POINT_WIN_BOOKING}/${uri}`;
};

export const getDimensions = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((rs, rj) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    var img = new Image();
    img.onload = function () {
      rs({
        width: img.width,
        height: img.height,
      });
    };

    img.onerror = function (error) {
      rj(error);
    };

    reader.onload = function (file) {
      img.src = file.target?.result?.toString() || '';
    };
  });
};
