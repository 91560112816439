export const GET_SHOPS = 'GET_SHOPS';

export interface ShopsFilter {
  query?: string;
  limit: number;
  page: number;
  showInfo?: boolean;
}

export const PageSizeTable = 10;
