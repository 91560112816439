// import { ResetPasswordInput } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export interface ResetPasswordState {
  loading: boolean;
}
export interface ResetPasswordAction {
  type: typeof RESET_PASSWORD;
  payload: any;
}
export interface ResetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
  payload: any;
}
export interface ResetPasswordError {
  type: typeof RESET_PASSWORD_ERROR;
  payload: AppError;
}
export type ResetPasswordActionTypes = ResetPasswordAction | ResetPasswordSuccess | ResetPasswordError;
