import EmptyLayout from '@commons/components/layouts/Empty';
import MasterLayout from '@commons/components/layouts/MasterLayout';
import { protectedComponent } from '@hoc/protectedComponent';
import { AuthRouter, ChangePasswordRouter } from '@modules/Auth/router';
import { HotShopRouter } from '@modules/HotShop/router';
import { SettingsRouter } from '@modules/Settings/router';
import { ShopsRouter } from '@modules/Shops/router';
import { RouteConfig } from 'react-router-config';

const routes: RouteConfig[] = [
  {
    component: EmptyLayout,
    routes: [
      ...AuthRouter,
      {
        component: protectedComponent(MasterLayout),
        routes: [ChangePasswordRouter, ...HotShopRouter, ...ShopsRouter, ...SettingsRouter],
      },
    ],
  },
];

export default routes;
