import env from '@/env';
import AuthLayout from '@commons/components/layouts/Auth';
import { setTitle } from '@helpers/dom';
import { getRedirectUrl } from '@helpers/history';
import { __ } from '@helpers/string';
import { getAuthLocalData, saveCodeLogin } from '@helpers/token';
import { LoginState } from '@modules/Auth/redux/action-types';
import { RootState } from '@redux/reducers';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import useLogin from '../../hooks/useLogin';
import LoginForm from './components/Form';

function LoginPage() {
  const { loading, onLogin } = useLogin();
  const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const history = useHistory();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');

  useEffect(() => {
    setTitle(__('Login'));
  }, []);

  useEffect(() => {
    if (code) {
      saveCodeLogin(code);
    } else {
      window.location.href = `${env.urlWinSuperAdmin}/login`;
    }

    // eslint-disable-next-line
  }, [code]);

  useEffect(() => {
    if (loginState?.data && getAuthLocalData()) {
      history.replace(getRedirectUrl());
    }
    // eslint-disable-next-line
  }, [loginState, getAuthLocalData()]);

  return (
    <AuthLayout>
      <LoginForm loading={loading} onLogin={onLogin} />
    </AuthLayout>
  );
}

export default LoginPage;
