import active_info from '@assets/images/left_nav/active_INFO.svg';
import ic_info from '@assets/images/left_nav/INFO.svg';
import ic_setting from '@assets/images/left_nav/ic_setting.svg';
import ic_setting_active from '@assets/images/left_nav/active_setting.svg';
import ic_shop from '@assets/images/left_nav/playlist.svg';
import ic_shop_active from '@assets/images/left_nav/active_playlist.svg';

import { getHistory } from '@helpers/history';
import { getHotShopsUrl, getSettingUrl, getShopsUrl } from '@helpers/url';
import { Menu } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

interface IProps {
  isCollapsed: boolean;
}

export default function SupperAdminLeftNavigation({ isCollapsed }: IProps) {
  const handleSelectMenu = ({ key }: any) => {
    getHistory().push(key);
  };

  const { t } = useTranslation(['leftNavigation']);

  const { pathname, search } = useLocation();

  const selectedKeys = useMemo(() => {
    const keys = `${pathname + search}`
      .split('/')
      .filter((r) => r)
      .reduce<string[]>((ac, cu) => {
        if (ac.length) {
          ac.push(`${ac[ac.length - 1]}/${cu}`);
        } else {
          ac.push(`/${cu}`);
        }
        return ac;
      }, []);

    if (keys.length) return keys;
    return ['/'];
  }, [pathname, search]);

  // const loginState = useSelector<RootState, LoginState>((state) => state.auth.loginState);

  const IconMenuItem = (
    key: string,
    icon: any,
    iconActive: any,
    alt: string,
    width?: number,
    iconActiveWidth?: number,
  ) => {
    return selectedKeys.includes(key) ? (
      <img src={iconActive} alt={alt} width={width} />
    ) : (
      <img src={icon} alt={alt} width={width} />
    );
  };

  const mouseDownHandler = (event: any, key: any) => {
    if (event.button === 1) {
      window.open(key);
    }
  };

  return (
    <>
      <Menu mode="inline" onSelect={handleSelectMenu} selectedKeys={selectedKeys} defaultOpenKeys={selectedKeys}>
        <Menu.Item
          key={getShopsUrl()}
          icon={IconMenuItem(getShopsUrl(), ic_info, active_info, 'ic_dashboard', 25, 30)}
          onMouseDown={(env) => mouseDownHandler(env, getShopsUrl())}
        >
          {t('shops')}
        </Menu.Item>
        <Menu.Item
          key={getHotShopsUrl()}
          icon={IconMenuItem(getHotShopsUrl(), ic_shop, ic_shop_active, 'ic_dashboard', 25, 30)}
          onMouseDown={(env) => mouseDownHandler(env, getHotShopsUrl())}
        >
          {t('hot-shop')}
        </Menu.Item>
        <Menu.Item
          key={getSettingUrl()}
          icon={IconMenuItem(getSettingUrl(), ic_setting, ic_setting_active, 'ic_dashboard', 25, 30)}
          onMouseDown={(env) => mouseDownHandler(env, getSettingUrl())}
        >
          {t('settings')}
        </Menu.Item>
      </Menu>
    </>
  );
}
