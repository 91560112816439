import { Restaurant } from '@/graphql/generated/graphql';
import TableHeader from '@commons/components/TableHeader';
import TableWithNumber from '@commons/components/TableWithNumber';
import { getImageUrl } from '@helpers/function-helper';
import useSetPageTitle from '@modules/App/hooks/useSetPageTitle';
import { Button, Form, Image, Input, Switch } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import TextTruncate from 'react-text-truncate';
import useGetShops from './hooks/useGetShops';
import useShowOrHideShop from './hooks/useShowOrHideShop';
import { PageSizeTable, ShopsFilter } from './types';

const Shops = () => {
  const { t } = useTranslation(['shops']);
  const [form] = useForm();
  const { setPageTitle } = useSetPageTitle();
  useEffect(() => {
    t && setPageTitle(t('shops'));
    // eslint-disable-next-line
  }, [t]);

  const [filterShop, setFilterShop] = useState<ShopsFilter>({ query: '', limit: PageSizeTable, page: 1 });

  const { isLoading, data } = useGetShops({ query: filterShop.query }, filterShop.limit, filterShop.page);

  const { mutate: setShowOrHide } = useShowOrHideShop();

  const columns: ColumnsType<any> = [
    {
      title: 'STT',
      key: '#',
      dataIndex: '#',
      width: 80,
      align: 'center',
    },
    {
      title: t('images'),
      dataIndex: 'media',
      key: 'media',
      width: 200,
      render: (icon: any, record: Restaurant) => {
        if (icon && icon?.url.length > 0) {
          return <Image className="table-item-img" src={record.media?.thumbnail} />;
        }
        return <Image className="table-item-img" src={getImageUrl('')} />;
      },
    },
    {
      title: t('shop'),
      key: 'name',
      dataIndex: 'name',

      render: (value: any, record) => (
        <TextTruncate containerClassName="w-100 d-block" line={2} element="span" truncateText="…" text={value} />
      ),
    },
    {
      title: t('show-or-hide'),
      width: 150,
      dataIndex: 'showInWFO',
      key: 'showInWFO',
      render: (value: any, record: Restaurant) => (
        <Switch
          defaultChecked={value}
          onChange={(value) => {
            onSetShowOrHide(record._id, value);
          }}
        />
      ),
    },
  ];

  const onSetShowOrHide = (id: string, status: boolean) => {
    setShowOrHide({ input: { restaurantIds: [id], showInApp: status } });
  };

  const onSearch = (values: any) => {
    setFilterShop({ ...filterShop, query: values.query });
  };

  return (
    <>
      <TableHeader>
        <div className="table-header-flex d-block d-xl-flex justify-content-between align-items-center">
          <div />
          <div className="table-actions">
            <Form
              layout="inline"
              form={form}
              onFinish={onSearch}
              className="justify-content-start justify-content-xl-end"
            >
              <Form.Item className="mb-2">
                <Input.Group compact>
                  <Form.Item name="query" className="w-full mr-0">
                    <Input className="input_search_header" placeholder={`${t('search_shop')}...`} />
                  </Form.Item>
                  <Button icon={<FiSearch color="#F80000" />} className="btn-search" htmlType="submit" />
                </Input.Group>
              </Form.Item>
            </Form>
          </div>
        </div>
      </TableHeader>
      <TableWithNumber
        scroll={{
          x: 1150,
        }}
        rowKey={(data: any) => data._id}
        columns={columns}
        rowClassName={(record, index) =>
          index % 2 === 0 ? 'table-row-height table-row-white ' : 'table-row-height table-row-gray'
        }
        dataSource={data?.findAllRestaurants?.data || []}
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true,
          pageSize: filterShop.limit,
          total: data?.findAllRestaurants?.total || 0,
          current: filterShop.page,
          onChange: (page) => {
            setFilterShop({
              ...filterShop,
              page,
            });
          },
        }}
      />
    </>
  );
};

export default Shops;
