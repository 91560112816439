import {
  ChangePasswordActionTypes,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_DONE,
} from '../action-types/change-password';
import { AppError } from '@commons/types';
// import { ChangePasswordInput } from '@/graphql/generated/graphql';

export const changePassword = (payload: any): ChangePasswordActionTypes => ({
  type: CHANGE_PASSWORD,
  payload,
});

export const changePasswordError = (payload: AppError): ChangePasswordActionTypes => ({
  type: CHANGE_PASSWORD_ERROR,
  payload,
});

export const changePasswordSuccess = (): ChangePasswordActionTypes => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordDone = (): ChangePasswordActionTypes => ({
  type: CHANGE_PASSWORD_DONE,
});
