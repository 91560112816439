import { protectedComponent } from '@hoc/protectedComponent';
import HotShopPage from '@modules/HotShop/page/index';
import NewHotShopPage from '@modules/HotShop/page/new/index';

export const HotShopRouter = [
  {
    path: '/hot-shop',
    exact: true,
    component: protectedComponent(HotShopPage),
  },
  {
    path: '/hot-shop/new',
    exact: true,
    component: protectedComponent(NewHotShopPage),
  },
];
