import { getSDK } from '@/graphql/client';
import { LoginWithEmailInput, LoginWithWinSsoInput } from '@/graphql/generated/graphql';

export const superAdminApi = async () => {
  return new Promise((resolve, reject) => {
    getSDK()
      .SuperAdmin()
      .then((data) => {
        resolve(data);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const loginByCode = async (payload: LoginWithWinSsoInput) => {
  return new Promise((resolve, reject) => {
    getSDK()
      .SuperAdminLoginByCode({ input: payload })
      .then((user) => {
        resolve(user);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const loginWithEmail = async (payload: LoginWithEmailInput) => {
  return new Promise((resolve, reject) => {
    getSDK()
      .LoginWithEmail({ input: payload })
      .then((user) => {
        resolve(user);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const refreshTokenApi = async (refreshToken: string) => {
  return new Promise((resolve, reject) => {
    //call api
    getSDK(true)
      .RefreshTokenMutation({ input: { refreshToken } })
      .then((user) => {
        resolve(user);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const logoutApi = async () => {
  return new Promise((resolve, reject) => {
    //call api
    getSDK(true)
      .logout()
      .then(() => {
        resolve(true);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

// export const verifyTokenAutoLogin = async (data: VerifyTokenAutoLoginInput) => {
//   return new Promise((resolve, reject) => {
//     //call api
//     getSDK()
//       .verifyTokenAutoLogin({ input: data })
//       .then((data) => {
//         resolve(data?.verifyTokenAutoLogin);
//       })
//       .catch((error) => reject(error?.response?.errors[0]));
//   });
// };

// export const forgotPasword = async (payload: ForgotPasswordInput) => {
//   return new Promise((resolve, reject) => {
//     //call api
//     getSDK()
//       .forgotPassword({ input: payload })
//       .then((user) => {
//         resolve(user);
//       })
//       .catch((error) => reject(error?.response?.errors[0]));
//   });
// };

// export const resetPassword = async (payload: ResetPasswordInput) => {
//   return new Promise((resolve, reject) => {
//     //call api
//     getSDK()
//       .resetPassword({ input: payload })
//       .then((user) => {
//         resolve(user);
//       })
//       .catch((error) => reject(error?.response?.errors[0]));
//   });
// };

// export const changePassword = async (payload: ChangePasswordInput) => {
//   return new Promise((resolve, reject) => {
//     //call api
//     getSDK()
//       .changePassword({ input: payload })
//       .then((data) => {
//         resolve(data);
//       })
//       .catch((error) => reject(error?.response?.errors[0]));
//   });
// };

// export const register = async (data: RegisterInput) => {
//   return new Promise((resolve, reject) => {
//     commitMutation<RegisterMutation>(environment, {
//       mutation: registerMutation,
//       variables: {
//         ...data,
//       },
//       onCompleted: (response, errors) => {
//         if (errors?.length) {
//           return reject(errors);
//         }
//         resolve(response);
//       },
//       onError: (error: Error) => {
//         reject(error);
//       },
//     });
//   });
// };
