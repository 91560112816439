import '@assets/styles/app.less';
import '@assets/styles/app.scss';
import { setHistory, setRedirectUrl } from '@helpers/history';
import { setDefaultLanguage } from '@helpers/token';
import { appStart } from '@modules/App/redux/actions';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';

export interface BaseLayoutProps {
  children: React.ReactNode;
}

const EmptyLayout = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setDefaultLanguage();
    setHistory(history);
    dispatch(appStart());
    setRedirectUrl('');
    // eslint-disable-next-line
  }, []);
  const { route } = props;
  return <>{renderRoutes(route.routes)}</>;
};
export default EmptyLayout;
